*{
  box-sizing: border-box;
}

.App{
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  color: #515151;
  min-height: 100vh;
  min-width: 100vw;
  padding-bottom: 10vh;
}
.listBelief{
  list-style: disc;
  font-size: 1.5rem;
  line-height: 3.25rem;
  color: #515151;
}

.App-header {
  padding: 0 20vw;
  width: 100vw;
  margin-bottom: 0;
}
.App-header h1 {
  font-size: 3rem;
  margin-top: 20%;
  
}
.App-header h4 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
}
.App-body{
  margin-top: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  padding: 0 20vw;
  display: flex;
  flex-direction: column;
}
.belief{
  font-size: 2rem;
  color: #515151;
}
.finalTag{
  font-size: 2.5rem;
  color: #515151;
}
.highlight{
  color: #00B4FF;
  font-weight: bold;
}
.badSpan{
  color: red;
  font-weight: bold;
}
.big{
  font-size: 2.75rem;
  font-weight: bold;
}
.italic{
  font-style: italic;
}
.bar{
  width: 100vw;
  height: 50px;
  background-image: linear-gradient(#EEEEEE, rgba(238, 238, 238, 0));
  position: fixed;
}

@media screen and (min-width: 500px) {
  .App{
    background-color: #EEEEEE;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    color: #515151;
    min-height: 100vh;
    padding-bottom: 10vh;
  }
  .listBelief{
    list-style: none;
    line-height: 3.25rem;
    color: #515151;
  }
  
  .App-header {
    padding: 0 20vw;
    margin-bottom: 0;
  }
  .App-header h1 {
    font-size: 3rem;
    margin-top: 10%;
  }
  .App-header h4 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 500;
  }
  .App-body{
    margin-top: 0;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding: 0 20vw;
    display: flex;
    flex-direction: column;
  }
  .belief{
    font-size: 2rem;
    color: #515151;
  }
  .finalTag{
    font-size: 2.5rem;
    color: #515151;
  }
  .highlight{
    color: #00B4FF;
    font-weight: bold;
  }
  .badSpan{
    color: red;
    font-weight: bold;
  }
  .big{
    font-size: 2.75rem;
    font-weight: bold;
  }
  .italic{
    font-style: italic;
  }
  .bar{
    width: 100vw;
    height: 50px;
    background-image: linear-gradient(#EEEEEE, rgba(238, 238, 238, 0));
    position: fixed;
  }
}